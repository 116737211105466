import Vue from 'vue'
import Vuex from 'vuex'
import global from './global'

Vue.use(Vuex)

export const store = new Vuex.Store ({
	strict: process.env.MIX_APP_ENV !== 'production',
	modules: {
		global,
	},
	plugins: [],
})
