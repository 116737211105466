<template>
  <div class="row">
    <div class="col-md-12 login-with">
      <p>Login with August99 Workspace</p>
      <div id="googleLogin"></div>
    </div>
    
    <div class="col-md-12 login-with">
      <p>Login with Agent Image Workspace</p>
      <div id="googleLoginAi"></div>
    </div>
    
    <div class="col-md-12 login-with">
      <p>Login with Oqulo Workspace</p>
      <div id="googleLoginOqulo"></div>
    </div>
    
    <div class="col-md-12 login-with">
      <p>Login with TaoCrowd Workspace</p>
      <div id="googleLoginTaoCrowd"></div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },

    beforeCreate: function () {
      console.log(this.$session)
    },

    mounted() {
      const handleCredentialResponse = (response) => {
        // 1 day/s session in milliseconds
        this.$session.start(1000 * 3600* 24 * 1) 
        this.$session.set('logapp', response.credentials)
        this.$router.push('/')
      }

      window.onload = () => {
        window.google.accounts.id.initialize({
          client_id: "486071497964-h6vmjmcn5u0vjmq1p9cp3o7ecpqbcm5t.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: false,
          hd: 'august99.com'
        });
        window.google.accounts.id.renderButton(
          document.getElementById("googleLogin"),
          { theme: "outline", size: "large" }  
        );
        
        window.google.accounts.id.initialize({
          client_id: "486071497964-h6vmjmcn5u0vjmq1p9cp3o7ecpqbcm5t.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: false,
          hd: 'agentimage.com'
        });
        window.google.accounts.id.renderButton(
          document.getElementById("googleLoginAi"),
          { theme: "outline", size: "large" }  
        );
        
        window.google.accounts.id.initialize({
          client_id: "486071497964-h6vmjmcn5u0vjmq1p9cp3o7ecpqbcm5t.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: false,
          hd: 'oqulo.com'
        });
        window.google.accounts.id.renderButton(
          document.getElementById("googleLoginOqulo"),
          { theme: "outline", size: "large" }  
        );
        
        window.google.accounts.id.initialize({
          client_id: "486071497964-h6vmjmcn5u0vjmq1p9cp3o7ecpqbcm5t.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: false,
          hd: 'taocrowd.com'
        });
        window.google.accounts.id.renderButton(
          document.getElementById("googleLoginTaoCrowd"),
          { theme: "outline", size: "large" }  
        );

        // window.google.accounts.id.prompt();
      }
    }
  }
</script>
