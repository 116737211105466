<template>
  <div>
    <!-- BEGIN: Form -->
    <validation-observer :ref="`form-edit-${_uid}`" v-slot="{handleSubmit, dirty, valid}">
      <form @submit.stop.prevent="handleSubmit(submit)">

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <!-- BEGIN: Form Group -->
            <div class="form-group">
              <!-- BEGIN: Validation -->
              <validation-provider
                rules="required:true|validUrl"
                v-slot="validationContext"
                name="Domain"
                vid="domain"
              >
                <!-- BEGIN: Label -->
                <label for="domain" class="d-block label-custom">Domain</label>
                <!-- END: Label -->
                <!-- BEGIN: Input -->
                <div class="d-flex flex-column flex-sm-row" style="gap: 10px">
                  <input
                    id="domain"
                    name="domain"
                    v-model="domain"
                    :state="getValidationState(validationContext)"
                    :class="{
                      'is-invalid': validationContext.errors[0],
                      'is-valid': validationContext.dirty && validationContext.valid,
                    }"
                    :disabled="data.item.origin !== undefined"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="https://agentimage.com/"
                  >
                  <button type="button" class="btn btn-lg btn-primary py-sm-1 px-5 position-relative" :disabled="data.item.origin !== undefined || spinner || dirty === false || valid === false" @click.stop.prevent="search()">
                    <span class="spinner-border spinner-border-sm mr-2 position-absolute left-0" style="left: 15px; top: 35%;" role="status" aria-hidden="true" v-show="searchSpinner"></span>
                    Search
                  </button>
                </div>
                <!-- END: Input -->

                <!-- BEGIN: Error Message -->
                <div class="invalid-feedback">
                  {{ validationContext.errors[0] }}
                </div>
                <!-- END: Error Message -->
                <small class="form-text" :style="{color: noticeColor}">{{ noticeText }}</small>
                <small class="form-text" v-show="data.item.origin !== undefined">
                  <div v-show="data.item.origin !== ''">
                    Server: {{ data.item.origin }}<br />
                  </div>
                  <em style="opacity: .8">Searching for a different domain? Refresh the page and try entering your input again.</em>
                </small>
              </validation-provider>
              <!-- END: Validation -->
            </div>
            <!-- END: Form Group -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row mb-5">
          <!-- BEGIN: Column -->
          <div class="col">
            <h3>Purge Cache</h3>
            <p>Clear cached files to force Cloudflare to fetch a fresh version of those files from your web server.</p>
            <p><strong>Note:</strong> This may temporarily degrade performance for your website and increase load on your origin.</p>
            <!-- BEGIN: Button -->
            <button type="button" class="btn btn-lg btn-primary" :disabled="data.item.hostname === undefined || spinner || dirty === false || valid === false" @click.stop.prevent="purge">
              <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-show="spinner"></span>
              Purge Cache
            </button>
            <!-- END: Button -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <h3>Development Mode(3 hours)</h3>
            <p>Temporarily bypass our cache allowing you to see changes to your origin server in realtime.
            <p><strong>Note:</strong> Enabling this feature can significantly increase origin server load. Development mode does not purge the cache so files will need to be purged after development mode expires.</p>
            <!-- BEGIN: Button -->
            <button type="button" class="btn btn-lg btn-primary" :disabled="data.item.hostname === undefined || spinner || dirty === false || valid === false" @click.stop.prevent="purge(this, 1)">
              <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-show="spinner"></span>
              Turn On
            </button>
            <!-- END: Button -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->
      </form>
    </validation-observer>
    <!-- END: Form -->
  </div>
</template>

<script>
  import global from '@/mixins/global'

  export default {
    mixins: [global],

    data() {
      return {
        domain: '',
        spinner: false,
        searchSpinner: false,
        noticeText: "IMPORTANT : With HTTP Protocol and without \"www\"",
        noticeColor: "#ff8888",
        data: {
          type: "",
          status: true,
          item: {},
          message: ""
        },
      }
    },

    methods: {
      submit() {},
      async search() {
        this.searchSpinner = true

        try {
          // eslint-disable-next-line no-unused-vars
          const {data} = await this.$http.post(`${process.env.VUE_APP_API_URL}/cloudflare/search`, {
            domain: this.domain,
          })

          this.noticeText = data.message
          this.noticeColor = data.item.id !== undefined ? "#0ddb29" : "#ff8888"
          
          this.data = data
        } catch (e) {
          this.noticeText = "IMPORTANT : With HTTP Protocol and without \"www\"",
          this.noticeColor = "#ff8888"
          this.$notify({
            type: 'error',
            // Error message is in general, might be an issue with cloudflare status, and token permissions
            text: 'There is a problem with the API connection.',
          })
        }
        
        this.searchSpinner = false
      },
      async purge(event, mode = 0)
      {
        if (this.data.item.hostname === undefined) {
          return false
        }

        this.spinner = true

        try {
          // eslint-disable-next-line no-unused-vars
          const {data} = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/cloudflare/${this.data.type}/${this.data.item.id}/${(mode ? 'development' : 'purge')}`,
            {
              domain: this.domain
            }
          )

          this.$notify({
            type: data.status ? 'success' : 'error',
            text: data.message,
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'There is an issue sending data to the domain either the domain doesn\'t exist or Initial Setup is not activated.',
          })
        }

        this.spinner = false
      }
    },
  }
</script>
