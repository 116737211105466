import IconFont from './../views/frontend/icon-font/Index.vue'
import SizeConversion from './../views/frontend/size-conversion/Index.vue'
import Package from './../views/frontend/package/Index.vue'
import Unblock from './../views/frontend/unblock/Index.vue'
import PurgeCache from './../views/frontend/purge-cache/Index.vue'
import Login from './../views/frontend/login/Index.vue'

const frontendRouters = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'icon-font'
    }
  },
  {
    path: '/icon-font',
    name: 'icon-font',
    component: IconFont,
    meta: {
      layout: 'default',
      displayTitle: true,
      iconTitle: 'list',
      title: 'Icon Font',
    }
  },
  {
    path: '/size-conversion',
    name: 'size-conversion',
    component: SizeConversion,
    meta: {
      layout: 'default',
      displayTitle: true,
      iconTitle: 'maximize',
      title: 'Size Conversion',
    }
  },
  {
    path: '/package',
    name: 'package',
    component: Package,
    meta: {
      displayTitle: false,
    },
    redirect: {name: 'themes'},
  },
  {
    path: '/package/themes',
    name: 'themes',
    component: Package,
    meta: {
      layout: 'default',
      iconTitle: 'star',
      title: 'Themes',
    }
  },
  {
    path: '/package/plugins',
    name: 'plugins',
    component: Package,
    meta: {
      layout: 'default',
      iconTitle: 'package',
      title: 'Plugins',
    }
  },
  {
    path: '/package/module',
    name: 'module',
    component: Package,
    meta: {
      layout: 'default',
      iconTitle: 'plus',
      title: 'Module',
    }
  },
  {
    path: '/unblock',
    name: 'unblock',
    component: Unblock,
    meta: {
      layout: 'default',
      displayTitle: true,
      iconTitle: 'unlock',
      title: 'ISP Unblocker',
    }
  },
  {
    path: '/purge-cache',
    name: 'purge-cache',
    component: PurgeCache,
    meta: {
      layout: 'default',
      displayTitle: true,
      iconTitle: 'database',
      title: 'Purge Cache',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'nosidebar',
    }
  },
]

export default frontendRouters
