export default {
	namespaced: true,
	
	state: {
		isNightMode: JSON.parse(localStorage.getItem("nightMode")) || false,
		isCollapse: JSON.parse(localStorage.getItem("collapseMenu")) || false
	},
	
	getters: {
		getNightMode (state) {
			return state.isNightMode
		},
		
		getCollapse (state) {
			return state.isCollapse
		},
	},
	
	mutations: {
		setNightMode (state, payload) {
			state.isNightMode = payload
		},
		
		setCollapse (state, payload) {
			state.isCollapse = payload
		},
	},
	
	actions: {
	
	}
}
