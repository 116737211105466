import Axios from 'axios'
import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import Notifications from 'vue-notification'
import VueEasySession from 'vue-easysession'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import './validate/rules'
import { store } from './store/index'
import Router from './router/index'
import App from './App'

// Axios
Axios.defaults.headers.common['Accept'] = 'application/json'
Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Vue.prototype.$http = Axios

// Notification
Vue.use(Notifications)

// Page progress bar
Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  thickness: '5px'
})

Vue.use(VueEasySession.install, {
  persist: true,
  keySession: 'logapp',
  expireSessionCallback: function () {
      window.location.href = '/login'
  }
})

// Form Validator
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Add Vue Directives
// const directives_files = require.context('./directives', true, /\.js$/i)
// directives_files.keys().map(key => Vue.directive(key.split('/').pop().split('.')[0], directives_files(key).default))

// Add Vue Components as Layout
const layoutFiles = require.context('./layouts/', true, /\.vue$/i)
layoutFiles.keys().map(key => Vue.component(`${key.split('/').pop().split('.')[0].toLocaleLowerCase()}-layout`, layoutFiles(key).default))

// Add Vue Components
const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

const app = new Vue({
  el: '#app',
  router: Router,
  store,
  render: h => h(App)
})

// Vue Router
// Initialize Progressbar each route change
app.$router.beforeEach((to, from, next) => {
  app.$Progress.start()
  next()
})

// eslint-disable-next-line no-unused-vars
app.$router.afterEach((to, from) => {
  app.$Progress.finish()
})

export default app
