<template>
  <div class="row">
    <!-- BEGIN: Column -->
    <div class="col-12">
      <div class="row">
        <div class="col-md col-12 form-group">
          <label for="search-icon-font" class="d-none">Search</label>
          <input type="text" class="form-control form-control-lg" id="search-icon-font" :placeholder='`Search ${fonts.length} Icons (Press "\\" to focus)`' v-model="search" ref="searchIconFont">
        </div>
        <div class="col-md-auto col-12 form-group">
          <label for="search-icon-category" class="d-none">Type</label>
          <select id="search-icon-category" class="form-control form-control-lg" v-model="category">
            <option value="">All</option>
            <option value="arrow">Arrow</option>
            <option value="copyright">Copyright</option>
            <option value="devices">Devices</option>
            <option value="file-type">File Type</option>
            <option value="layout">Layout</option>
            <option value="logo">Logo</option>
            <option value="multi-color">Multi Color</option>
            <option value="miscellaneous">Miscellaneous</option>
            <option value="search">Search</option>
            <option value="social-media">Social Media</option>
          </select>
        </div>
      </div>

      <p class="mt-3">Basic usage: add prefix `ai-font-` of class, sample "<strong>ai-font-agentimage-mono</strong>".</p>
      <p class="mt-3">Important Note: Some of the icons are being deprecated but still usable, this will remove permanently on Q1 of 2024.</p>
    </div>
    <!-- END: Column -->

    <!-- BEGIN: Font List -->
    <template v-for="font of filterFontsByName">
      <div class="icon-col col-lg-3 col-md-4 col-sm-6 my-3" :key="font.name">
        <div class="icon-wrapper text-center" @click="copyToClipboard">
          <div class="icon-font" ref="fonts">
            <span :class="`ai-font-${font.name}`">
              <template v-if="font.category === 'multi-color'">
                <i></i>
              </template>
            </span>
          </div>
          <!-- <input class="icon-name py-2" :value="font.name" disabled>-->
          <div class="icon-name py-2">
            <span style="font-size: 0">ai-font-</span>{{ font.name }}
          </div>
        </div>
      </div>
    </template>
    <!-- END: Font List -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        search: '',
        category: '',
        fonts: [
          {category: 'logo', name: 'agentimage-mono'},
          {category: 'logo', name: 'agentimage-logo'},
          {category: 'logo', name: 'agentimage-stacked'},
          {category: 'logo', name: 'thedesignpeople-longform'},
          {category: 'logo', name: 'real-logo'},
          {category: 'logo', name: 'august99-mono'},
          {category: 'logo', name: 'august99-longform'},
          {category: 'logo', name: 'august99'},
          {category: 'logo', name: 'loft-logo'},
          {category: 'logo', name: 'loft-mono'},
          {category: 'logo', name: 'reduxlabs-longform'},
          {category: 'logo', name: 'century-21'},
          {category: 'logo', name: 'compass'},
          {category: 'logo', name: 'compass-mono'},
          {category: 'logo', name: 'remax'},
          {category: 'logo', name: 'sothebys'},
          {category: 'logo', name: 'douglas-elliman'},
          {category: 'logo', name: 'keller-williams'},
          {category: 'social-media', name: 'active-rain'},
          {category: 'social-media', name: 'bbb'},
          {category: 'social-media', name: 'blogger'},
          {category: 'social-media', name: 'caimeiju'},
          {category: 'social-media', name: 'caimeiju-longform'},
          {category: 'social-media', name: 'facebook'},
          {category: 'social-media', name: 'facebook-text'},
          {category: 'social-media', name: 'flickr'},
          {category: 'social-media', name: 'foursquare'},
          // {category: 'social-media', name: 'google-plus'},
          {category: 'social-media', name: 'google'},
          {category: 'social-media', name: 'google-alt'},
          {category: 'social-media', name: 'google-my-business-outline'},
          {category: 'social-media', name: 'nextdoor'},
          {category: 'social-media', name: 'nextdoor-longform'},
          {category: 'social-media', name: 'linkedin'},
          {category: 'social-media', name: 'houzz'},
          {category: 'social-media', name: 'instagram'},
          {category: 'social-media', name: 'instagram-text'},
          {category: 'social-media', name: 'threads-app'},
          {category: 'social-media', name: 'realtor-alt'},
          {category: 'social-media', name: 'pinterest'},
          {category: 'social-media', name: 'skype'},
          {category: 'social-media', name: 'snapchat'},
          {category: 'social-media', name: 'spotify'},
          {category: 'social-media', name: 'spotify-outline'},
          {category: 'social-media', name: 'tiktok'},
          {category: 'social-media', name: 'trulia'},
          {category: 'social-media', name: 'tumblr'},
          {category: 'social-media', name: 'twitter'},
          {category: 'social-media', name: 'vimeo'},
          {category: 'social-media', name: 'whatsapp'},
          {category: 'social-media', name: 'whatsapp-outline'},
          {category: 'social-media', name: 'yahoo'},
          {category: 'social-media', name: 'yelp'},
          {category: 'social-media', name: 'youtube'},
          {category: 'social-media', name: 'zillow'},
          {category: 'social-media', name: 'calendly'},
          {category: 'social-media', name: 'gettr'},
          {category: 'copyright', name: 'realtor-mls'},
          {category: 'copyright', name: 'realtor'},
          {category: 'copyright', name: 'mls'},
          {category: 'copyright', name: 'eho'},
          {category: 'copyright', name: 'footer-logo'},
          {category: 'search', name: 'magnifying-glass-a'},
          {category: 'search', name: 'magnifying-glass-b'},
          {category: 'search', name: 'magnifying-glass-c'},
          {category: 'search', name: 'magnifying-glass-d'},
          {category: 'search', name: 'magnifying-glass-e'},
          {category: 'search', name: 'magnifying-glass-f'},
          {category: 'search', name: 'magnifying-glass-g'},
          {category: 'search', name: 'magnifying-glass-h'},
          {category: 'miscellaneous', name: 'close-a'},
          {category: 'miscellaneous', name: 'close-b'},
          {category: 'miscellaneous', name: 'close-c'},
          {category: 'miscellaneous', name: 'close-d'},
          {category: 'miscellaneous', name: 'close-e'},
          {category: 'miscellaneous', name: 'close-f'},
          {category: 'miscellaneous', name: 'close-g'},
          {category: 'miscellaneous', name: 'comment'},
          {category: 'miscellaneous', name: 'comment-a'},
          {category: 'miscellaneous', name: 'order-f'},
          {category: 'miscellaneous', name: 'filter-f'},
          {category: 'miscellaneous', name: 'heart-fill'},
          {category: 'miscellaneous', name: 'heart-outline'},
          {category: 'miscellaneous', name: 'mobile-a'},
          {category: 'miscellaneous', name: 'mobile-a-alt'},
          {category: 'miscellaneous', name: 'mobile-b'},
          {category: 'miscellaneous', name: 'mobile-b-alt'},
          {category: 'miscellaneous', name: 'person-a'},
          {category: 'miscellaneous', name: 'person-a-alt'},
          {category: 'miscellaneous', name: 'person-group-a'},
          {category: 'miscellaneous', name: 'person-group-a-alt'},
          {category: 'miscellaneous', name: 'list-a'},
          {category: 'miscellaneous', name: 'list-a-alt'},
          {category: 'miscellaneous', name: 'list-b'},
          {category: 'miscellaneous', name: 'list-b-alt'},
          {category: 'miscellaneous', name: 'measurement-a'},
          {category: 'miscellaneous', name: 'measurement-b'},
          {category: 'miscellaneous', name: 'measurement-c'},
          {category: 'miscellaneous', name: 'crane-a'},
          {category: 'miscellaneous', name: 'crane-b'},
          {category: 'miscellaneous', name: 'crane-c'},
          {category: 'miscellaneous', name: 'phone-alt'},
          {category: 'miscellaneous', name: 'phone-alt-o'},
          {category: 'miscellaneous', name: 'edit'},
          {category: 'miscellaneous', name: 'rocket-ship'},
          {category: 'miscellaneous', name: 'bed'},
          {category: 'miscellaneous', name: 'bed-a'},
          {category: 'miscellaneous', name: 'buildings'},
          {category: 'miscellaneous', name: 'calendar'},
          {category: 'miscellaneous', name: 'cellphone'},
          {category: 'miscellaneous', name: 'check-a'},
          {category: 'miscellaneous', name: 'check-list'},
          {category: 'miscellaneous', name: 'check-o'},
          {category: 'miscellaneous', name: 'coin'},
          {category: 'miscellaneous', name: 'camera-fill'},
          {category: 'miscellaneous', name: 'dollar-o'},
          {category: 'miscellaneous', name: 'dollar-a'},
          {category: 'miscellaneous', name: 'envelope'},
          {category: 'miscellaneous', name: 'envelope-a'},
          {category: 'miscellaneous', name: 'envelope-filled-a'},
          {category: 'miscellaneous', name: 'envelope-outline-b'},
          {category: 'miscellaneous', name: 'envelope-outline-a'},
          {category: 'miscellaneous', name: 'fax'},
          {category: 'miscellaneous', name: 'fax-a'},
          {category: 'miscellaneous', name: 'fax-b'},
          {category: 'miscellaneous', name: 'business-card'},
          {category: 'miscellaneous', name: 'folder'},
          {category: 'miscellaneous', name: 'funds'},
          {category: 'miscellaneous', name: 'house'},
          {category: 'miscellaneous', name: 'house-f'},
          {category: 'miscellaneous', name: 'house-i'},
          {category: 'miscellaneous', name: 'home-a'},
          {category: 'miscellaneous', name: 'language'},
          {category: 'miscellaneous', name: 'lock-a'},
          {category: 'miscellaneous', name: 'lock-b'},
          {category: 'miscellaneous', name: 'lock-c'},
          {category: 'miscellaneous', name: 'lock-d'},
          {category: 'miscellaneous', name: 'map'},
          {category: 'miscellaneous', name: 'location'},
          {category: 'miscellaneous', name: 'location-a'},
          {category: 'miscellaneous', name: 'location-b'},
          {category: 'miscellaneous', name: 'location-c'},
          {category: 'miscellaneous', name: 'time-a'},
          {category: 'miscellaneous', name: 'notes-a'},
          {category: 'miscellaneous', name: 'medal'},
          {category: 'miscellaneous', name: 'organizer-a'},
          {category: 'miscellaneous', name: 'phone'},
          {category: 'miscellaneous', name: 'phone-a'},
          {category: 'miscellaneous', name: 'question-o'},
          {category: 'miscellaneous', name: 'rss'},
          {category: 'miscellaneous', name: 'settings'},
          {category: 'miscellaneous', name: 'settings-o'},
          {category: 'miscellaneous', name: 'showers'},
          {category: 'miscellaneous', name: 'showers-a'},
          {category: 'miscellaneous', name: 'tag'},
          {category: 'miscellaneous', name: 'user'},
          {category: 'miscellaneous', name: 'video'},
          {category: 'miscellaneous', name: 'wordpress'},
          {category: 'miscellaneous', name: 'world-f'},
          {category: 'miscellaneous', name: 'world-o'},
          {category: 'miscellaneous', name: 'map-f'},
          {category: 'miscellaneous', name: 'envelope-o'},
          {category: 'miscellaneous', name: 'envelope-f'},
          {category: 'miscellaneous', name: 'menu'},
          {category: 'miscellaneous', name: 'phone-o'},
          {category: 'miscellaneous', name: 'menu-o'},
          {category: 'miscellaneous', name: 'car'},
          {category: 'miscellaneous', name: 'play-button-a'},
          {category: 'miscellaneous', name: 'pause-button-a'},
          {category: 'miscellaneous', name: 'circle-a'},
          {category: 'miscellaneous', name: 'volume-a'},
          {category: 'miscellaneous', name: 'mute'},
          {category: 'miscellaneous', name: 'pencil'},
          {category: 'miscellaneous', name: 'delete-fill'},
          {category: 'miscellaneous', name: 'delete-outline'},
          {category: 'miscellaneous', name: 'star-outline'},
          {category: 'miscellaneous', name: 'star-fill'},
          {category: 'miscellaneous', name: 'loading-c'},
          {category: 'miscellaneous', name: 'loading-a'},
          {category: 'miscellaneous', name: 'loading-b'},
          {category: 'miscellaneous', name: 'paper-airplane'},
          {category: 'miscellaneous', name: 'paper-airplane-alt'},
          {category: 'miscellaneous', name: 'paper-airplane-outline'},
          {category: 'miscellaneous', name: 'check'},
          {category: 'miscellaneous', name: 'x-sign'},
          {category: 'miscellaneous', name: 'clock'},
          {category: 'miscellaneous', name: 'checkbox-a'},
          {category: 'miscellaneous', name: 'send'},
          {category: 'miscellaneous', name: 'package-tips-and-articles'},
          {category: 'miscellaneous', name: 'package-newsletter'},
          {category: 'miscellaneous', name: 'package-others'},
          {category: 'miscellaneous', name: 'package-properties'},
          {category: 'miscellaneous', name: 'package-lead-generation'},
          {category: 'miscellaneous', name: 'package-management'},
          {category: 'miscellaneous', name: 'package-marketing'},
          {category: 'miscellaneous', name: 'package-features'},
          {category: 'miscellaneous', name: 'package-financing'},
          {category: 'miscellaneous', name: 'package-communities'},
          {category: 'file-type', name: 'file-code'},
          {category: 'file-type', name: 'file-excel'},
          {category: 'file-type', name: 'file-f'},
          {category: 'file-type', name: 'file-image-o'},
          {category: 'file-type', name: 'file-image-p'},
          {category: 'file-type', name: 'file-o'},
          {category: 'file-type', name: 'file-pdf'},
          {category: 'file-type', name: 'file-powerpoint'},
          {category: 'file-type', name: 'file-sound'},
          {category: 'file-type', name: 'file-text-f'},
          {category: 'file-type', name: 'file-text-o'},
          {category: 'file-type', name: 'file-text-r'},
          {category: 'file-type', name: 'file-video'},
          {category: 'file-type', name: 'file-word'},
          {category: 'file-type', name: 'file-zip'},
          {category: 'file-type', name: 'document-a'},
          {category: 'devices', name: 'devices-desktop'},
          {category: 'devices', name: 'devices-laptop-a'},
          {category: 'devices', name: 'devices-laptop-b'},
          {category: 'devices', name: 'devices-tablet'},
          {category: 'devices', name: 'devices-mobile'},
          {category: 'layout', name: 'layout-list'},
          {category: 'layout', name: 'layout-list-active'},
          {category: 'layout', name: 'layout-table'},
          {category: 'layout', name: 'layout-table-active'},
          {category: 'layout', name: 'layout-grid'},
          {category: 'layout', name: 'layout-grid-active'},
          {category: 'arrow', name: 'arrow-a'},
          {category: 'arrow', name: 'arrow-b'},
          {category: 'arrow', name: 'arrow-c'},
          {category: 'arrow', name: 'arrow-d'},
          {category: 'arrow', name: 'arrow-e'},
          {category: 'arrow', name: 'arrow-f'},
          {category: 'arrow', name: 'arrow-g'},
          {category: 'arrow', name: 'arrow-h'},
          {category: 'arrow', name: 'arrow-i'},
          {category: 'arrow', name: 'arrow-j'},
          // {category: 'arrow', name: 'arrow-a-u'},
          // {category: 'arrow', name: 'arrow-a-d'},
          // {category: 'arrow', name: 'arrow-a-n'},
          // {category: 'arrow', name: 'arrow-a-p'},
          // {category: 'arrow', name: 'arrow-b-u'},
          // {category: 'arrow', name: 'arrow-b-d'},
          // {category: 'arrow', name: 'arrow-b-n'},
          // {category: 'arrow', name: 'arrow-b-p'},
          // {category: 'arrow', name: 'arrow-c-u'},
          // {category: 'arrow', name: 'arrow-c-d'},
          // {category: 'arrow', name: 'arrow-c-n'},
          // {category: 'arrow', name: 'arrow-c-p'},
          // {category: 'arrow', name: 'arrow-d-u'},
          // {category: 'arrow', name: 'arrow-d-d'},
          // {category: 'arrow', name: 'arrow-d-n'},
          // {category: 'arrow', name: 'arrow-d-p'},
          // {category: 'arrow', name: 'arrow-e-u'},
          // {category: 'arrow', name: 'arrow-e-d'},
          // {category: 'arrow', name: 'arrow-e-n'},
          // {category: 'arrow', name: 'arrow-e-p'},
          // {category: 'arrow', name: 'arrow-f-u'},
          // {category: 'arrow', name: 'arrow-f-d'},
          // {category: 'arrow', name: 'arrow-f-n'},
          // {category: 'arrow', name: 'arrow-f-p'},
          // {category: 'arrow', name: 'arrow-g-u'},
          // {category: 'arrow', name: 'arrow-g-d'},
          // {category: 'arrow', name: 'arrow-g-n'},
          // {category: 'arrow', name: 'arrow-g-p'},
          // {category: 'arrow', name: 'arrow-h-u'},
          // {category: 'arrow', name: 'arrow-h-d'},
          // {category: 'arrow', name: 'arrow-h-n'},
          // {category: 'arrow', name: 'arrow-h-p'},
          // {category: 'arrow', name: 'arrow-i-u'},
          // {category: 'arrow', name: 'arrow-i-d'},
          // {category: 'arrow', name: 'arrow-i-n'},
          // {category: 'arrow', name: 'arrow-i-p'},
          // {category: 'multi-color', name: 'mc-text-facebook'},
          // {category: 'multi-color', name: 'mc-text-linkedin'},
          // {category: 'multi-color', name: 'mc-text-pinterest'},
          // {category: 'multi-color', name: 'mc-text-twitter'},
          // {category: 'multi-color', name: 'mc-text-youtube'},
          // {category: 'multi-color', name: 'mc-text-zillow'},
        ]
      }
    },

    mounted() {
      // Register an event listener when the Vue component is ready
      window.addEventListener('keypress', this.searchIconFontFocus)

      this.$refs.searchIconFont.focus()
    },

    beforeDestroy() {
      // Unregister the event listener before destroying this Vue instance
      window.removeEventListener('keypress', this.searchIconFontFocus)
    },

    computed: {
      filterFontsByName() {
        return this.fonts.filter(font => font.name.toLowerCase().indexOf(this.search.toLowerCase())> -1 && (this.category !== '' ? font.category === this.category : true))
      },
    },

    methods: {
      searchIconFontFocus(event) {
        if (event.key === '\\') {
          event.preventDefault()
          this.$refs.searchIconFont.focus()
        }
      },

      copyToClipboard(e) {
        const targetEl = e.currentTarget
        let range = document.createRange();

        range.selectNode(targetEl.querySelector('.icon-name'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();

        this.$notify({
          type: 'success',
          title: 'Copied to Clipboard',
          duration: 1000,
          speed: 1000,
        })
      }
    }
  }
</script>
