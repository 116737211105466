<template>
  <div class="sidebar">
    <div>
      <!-- BEGIN: Collapse -->
      <input type="checkbox" class="d-none" id="toogleCollapse" v-model="collapse">
      <label class="collapser d-none d-lg-flex" for="toogleCollapse">
        <span :class="`feather feather-arrow-left`">
          <svg><use :xlink:href="`/svg/feather-sprite.svg#arrow-left`" /></svg>
        </span>
      </label>
      <!-- END: Collapse -->
      <!-- BEGIN: Logo -->
      <div class="logo">
        <router-link :to="{name: 'home'}">
          <span class="agentimage-tools-svg agentimage-longform">
            <svg>
              <use xlink:href="/svg/agentimage-sprite.svg#icon-longform" />
            </svg>
          </span>
          <span class="agentimage-tools-svg agentimage-mono">
            <svg>
              <use xlink:href="/svg/agentimage-sprite.svg#icon-mono" />
            </svg>
          </span>
        </router-link>
      </div>
      <!-- END: Logo -->
      <!-- BEGIN: Menu -->
      <ul class="menu">
        <template v-for="route in filteredRoutes">
          <li :key="route.name">
            <router-link :to="{name: route.name}">
              <span :class="`feather feather-${route.meta.iconTitle}`">
                <svg><use :xlink:href="`/svg/feather-sprite.svg#${route.meta.iconTitle}`" /></svg>
              </span>
              {{ route.meta.title }}
            </router-link>

            <template v-if="route.children">
              <ul class="nav flex-column pl-5">
                <li class="nav-item" v-for="routeChildren in route.children" :key="routeChildren.name">
                  <router-link :to="{name: routeChildren.name}" class="nav-link px-0">
                    {{ routeChildren.meta.title }}
                  </router-link>
                </li>
              </ul>
            </template>
          </li>
        </template>
      </ul>
      <!-- END: Logo -->
    </div>
    <div>
      <!-- BEGIN: Switcher -->
      <div class="switcher px-3">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="toogleNightMode" v-model="nightMode">
          <label class="custom-control-label" for="toogleNightMode">Light/Dark Mode</label>
        </div>
      </div>
      <!-- END: Switcher -->
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex'

  export default {
    data() {
      return {
        routes: this.$router.options.routes,
        nightMode: false,
        collapse: false,
      }
    },

    watch: {
      nightMode: function() {
        localStorage.setItem("nightMode", JSON.stringify(this.nightMode));
        this.SET_NIGHTMODE(this.nightMode)
      },

      collapse: function() {
        localStorage.setItem("collapseMenu", JSON.stringify(this.collapse));
        this.SET_COLLAPSE(this.collapse)
      },
    },

    mounted() {
      this.nightMode = this.GET_NIGHTMODE
      this.collapse = this.GET_COLLAPSE
    },

    computed: {
      ...mapGetters({
        GET_NIGHTMODE: 'global/getNightMode',
        GET_COLLAPSE: 'global/getCollapse',
      }),

      filteredRoutes() {
        return this.routes.filter(route => {
          if (route.children) {
            route.children.filter(route => route.meta.hideInMenu !== true)
          }

          return route.meta !== undefined && route.meta.title !== undefined
        })
      },
    },

    methods: {
      ...mapMutations({
        SET_NIGHTMODE: 'global/setNightMode',
        SET_COLLAPSE: 'global/setCollapse',
      }),
    },
  }
</script>
