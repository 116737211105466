const global = {
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    serializeQuery (params, prefix) {
      const query = Object.keys(params).map((key) => {
        const value  = params[key]
      
        if (params.constructor === Array) {
          // key = `${prefix}[]`
          key = prefix
        } else if (params.constructor === Object) {
          key = (prefix ? `${prefix}[${key}]` : key)
        }
      
        if (typeof value === 'object') {
          return this.serializeQuery(value, key)
        } else {
          return `${key}=${encodeURIComponent(value)}`
        }
      })
    
      return [].concat.apply([], query).join('&')
    },
    isValidUrl (url) {
      const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
      
      return regexp.test(url);
    },
    removeHttpProtocolDomain (url) {
      url.replace(' ', '')
    
      if (!/^((http|https|ftp):\/\/)/.test(url))
        url = "https://" + url
    
      url = url.replace('www.', '')
    
      return new URL(url).hostname
    },
    urlBeautifier (url) {
      if (this.isValidUrl(url)) return this.removeHttpProtocolDomain(url)
        else return url
    },
  }
}

export default global
