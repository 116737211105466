import Vue from 'vue';
import VueRouter from 'vue-router';
import frontendRouters from './frontend'
import fallbackRouters from './fallback'

let vueRoutes = []

// Routes for Frontend
// eslint-disable-next-line no-unused-vars
frontendRouters.map((v,i) => vueRoutes.push(v))

// Fallback Routes
// eslint-disable-next-line no-unused-vars
fallbackRouters.map((v,i) => vueRoutes.push(v))

Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  routes: vueRoutes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
