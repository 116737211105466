/**
 * Parses the Link header to extract the next page URL
 * @param {string} linkHeader - The Link header string from the GitLab API
 * @returns {string|null} - The URL for the next page or null if no next page
 */
export function parseLinkHeader(linkHeader) {
	// Split the header into individual links
	const links = linkHeader.split(',');

	// Find the "next" link
	const nextLink = links.find((link) => link.includes('rel="next"'));

	if (!nextLink) {
		return null;
	}

	// Extract the URL from the link
	const matches = nextLink.match(/<([^>]+)>/);
	return matches ? matches[1] : null;
}

/**
 * Fetches data using GraphQL API
 *
 * @param {Object} options
 * @param {string} [options.url=`${process.env.VUE_APP_GITLAB_URL}/api/graphql`]
 * @param {string} [options.token=process.env.VUE_APP_GITLAB_API_TOKEN]
 * @param {string} options.query
 * @param {Object} [options.variables={}]
 * @returns {Promise<any>}
 */
export async function fetchGraphql({
	url = `${process.env.VUE_APP_GITLAB_URL}/api/graphql`,
	token = process.env.VUE_APP_GITLAB_API_TOKEN,
	query,
	variables = {},
}) {
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Private-Token': token,
		},
		body: JSON.stringify({ query, variables }),
	});

	return await response.json();
}

/**
 * Recursively fetches paginated data from GraphQL API
 *
 * @param {Object} options
 * @param {string} [options.url=`${process.env.VUE_APP_GITLAB_URL}/api/graphql`]
 * @param {string} [options.token=process.env.VUE_APP_GITLAB_API_TOKEN]
 * @param {string} options.query
 * @param {Object} [options.variables={}]
 * @param {string} options.path
 * @param {string|null} [options.cursor=null]
 * @param {Array} [options.accumulated=[]]
 * @param {number} [options.delay=100]
 * @returns {Promise<Array>}
 */
export async function recursiveFetchGraphQL({
	url = `${process.env.VUE_APP_GITLAB_URL}/api/graphql`,
	token = process.env.VUE_APP_GITLAB_API_TOKEN,
	query,
	variables = {},
	path,
	cursor = null,
	accumulated = [],
	delay = 100,
}) {
	const result = await fetchGraphql({
    url,
    token,
		query,
		variables: {
			...variables,
			...(variables.first ? { after: cursor } : { before: cursor }),
		},
	});

	if (result.errors) {
		throw new Error(result.errors[0].message);
	}

	const data = path.split('.').reduce((obj, key) => obj[key], result.data);
	const allItems = [...accumulated, ...data.nodes];

	if (data.pageInfo.hasNextPage) {
		await new Promise((resolve) => setTimeout(resolve, delay));

		return recursiveFetchGraphQL({
			query,
			variables,
			path,
			cursor: variables.first
				? data.pageInfo.endCursor
				: data.pageInfo.startCursor,
			accumulated: allItems,
			delay,
		});
	}

	return allItems;
}
