<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <!-- BEGIN: Form Group -->
        <!-- END: Form Group -->
        <div class="form-group">
          <label for="base-pixel-size">Base pixel size</label>
          <input type="number" id="base-pixel-size" class="form-control form-control-lg" v-model="basePixelSize">
        </div>
        <!-- END: Form Group -->
        <!-- BEGIN: Form Group -->
        <div class="form-group">
          <label for="font-size-pixel">Font size in pizel</label>
          <input type="number" id="font-size-pixel" class="form-control form-control-lg" v-model="fontSizePixel">
        </div>
        <!-- END: Form Group -->
        <!-- BEGIN: Form Group -->
        <div class="form-group">
          <label for="line-height">Line height(photoshop to css)</label>
          <input type="number" id="line-height" class="form-control form-control-lg" v-model="lineHeight">
        </div>
        <!-- END: Form Group -->
        <!-- BEGIN: Form Group -->
        <div class="form-group">
          <label for="letter-spacing">Letter spacing(photoshop to css)</label>
          <input type="number" id="letter-spacing" class="form-control form-control-lg" v-model="letterSpacing">
        </div>
        <!-- END: Form Group -->
        <!-- BEGIN: Form Group -->
        <div class="form-group">
          <label for="viewport">Viewport Size(width/height)</label>
          <input type="number" id="viewport" class="form-control form-control-lg" v-model="viewportSize">
        </div>
        <!-- END: Form Group -->
      </div>
      <div class="col-md-8">
        <!-- BEGIN: Card -->
        <div class="card border-0">
          <div class="card-body" :style="`line-height: ${fontLineHeight}; letter-spacing: ${fontLetterSpacing}em;`">
            <p class="card-text text-dark" :style="`font-size: ${fontViewport}vw`">This paragraph is using viewport width/height to change font sizes by changing viewport size value or resizing your browser.</p>
            <p class="card-text" :style="`font-size: ${fontSizePixel}px`">This paragraph is default paragraph using font size in px.</p>
            <p class="card-text" :style="`font-size: ${fontSizePixel}px`">Both paragraph are using same line height and letter spacing.</p>
          </div>
        </div>
        <!-- END: Card -->
        <!-- BEGIN: CSS Text -->
        <div class="card border-0 mt-3">
          <div class="card-body">
            <pre class="mb-0"><code class="w-100">.html {
    font-size: {{ basePixelSize }}px;
}
.element-class {
    // Font size in EM
    font-size: {{ fontToEM }}em;
    // Font size in PT
    font-size: {{ fontToPT }}pt;
    // Font size in Percentage
    font-size: {{ fontToPercentage }}%;
    // Font size in Viewport width/height
    font-size: {{ fontViewport }}vw;
    font-size: {{ fontViewport }}vh;
    // Line height and letter spacing
    line-height: {{ fontLineHeight }};
    letter-spacing: {{ fontLetterSpacing }}em;
}</code></pre>
          </div>
        </div>
        <!-- END: CSS Text -->
        <!-- BEGIN: Description -->
        <div class="h5 mt-5">Relative Lengths</div>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <th style="width: 60px">Unit</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>em</td>
                <td>Relative to the font-size of the element (2em means 2 times the size of the current font)</td>
              </tr>
              <tr>
                <td>ex</td>
                <td>Relative to the x-height of the current font (rarely used)</td>
              </tr>
              <tr>
                <td>ch</td>
                <td>Relative to the width of the "0" (zero)</td>
              </tr>
              <tr>
                <td>rem</td>
                <td>Relative to font-size of the root element</td>
              </tr>
              <tr>
                <td>vw</td>
                <td>Relative to 1% of the width of the viewport*</td>
              </tr>
              <tr>
                <td>vh</td>
                <td>Relative to 1% of the height of the viewport*</td>
              </tr>
              <tr>
                <td>vmin</td>
                <td>Relative to 1% of viewport's* smaller dimension</td>
              </tr>
              <tr>
                <td>vmax</td>
                <td>Relative to 1% of viewport's* larger dimension</td>
              </tr>
              <tr>
                <td>%</td>
                <td>Relative to the parent element</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Description -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        basePixelSize: 16,
        fontSizePixel: 16,
        lineHeight: 24,
        letterSpacing: 50,
        viewportSize: 1600,
      }
    },

    computed: {
      fontToEM() {
        return this.fontSizePixel === 0 ? 0 : this.fontSizePixel/this.basePixelSize;
      },

      fontToPT() {
        return this.fontSizePixel === 0 ? 0 : (this.fontSizePixel * 0.75).toFixed(0);
      },

      fontToPercentage() {
        return (this.fontToEM * 100).toFixed(0);
      },

      fontViewport() {
        return parseFloat((this.fontSizePixel / (this.viewportSize*0.01)).toFixed(3));
      },

      fontLineHeight() {
        return parseFloat((this.lineHeight / this.fontSizePixel).toFixed(1))
      },

      fontLetterSpacing() {
        return parseFloat((this.letterSpacing / 1000).toFixed(6))
      },
    },
  }
</script>
