<template>
  <div class="nosidebar-wrapper">
    <!-- BEGIN: Main Content -->
    <div class="main-content w-100" :class="`page-${$route.name}`">
      <div class="page-content">
        <div class="container">
          <!-- BEGIN: Logo -->
          <div class="logo">
            <router-link :to="{name: 'home'}">
              <span class="agentimage-tools-svg agentimage-longform">
                <svg>
                  <use xlink:href="/svg/agentimage-sprite.svg#icon-longform" />
                </svg>
              </span>
            </router-link>
          </div>
          <!-- END: Logo -->

          <div class="row">
            <div class="col-12">
              <div id="page-title" class="row" v-if="$route.meta.displayTitle !== false">
                <div class="col">
                  <h1>{{ $route.meta.title }}</h1>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <slot></slot>
            </div>
          </div>

        </div>
      </div>

      <div class="container copyright mt-5">
        Copyright {{ new Date().getFullYear()  }} <a href="https://www.agentimage.com" target="_blank">Agent Image</a>, a Division of The Design People, Inc. All Rights Reserved.
      </div>
    </div>
    <!-- END: Main Content -->
  </div>
</template>

<script>
  export default {
    name: 'nosidebar'
  }
</script>
