<template>
  <div>
    <!-- BEGIN: Form -->
    <validation-observer :ref="`form-edit-${_uid}`" v-slot="{handleSubmit, passed, dirty, valid}">
      <form @submit.stop.prevent="handleSubmit(submit)">

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <!-- BEGIN: Form Group -->
            <div class="form-group">
              <!-- BEGIN: Validation -->
              <validation-provider
                rules="required:true|validUrl"
                v-slot="validationContext"
                name="Domain"
                vid="domain"
              >
                <!-- BEGIN: Label -->
                <label for="domain" class="d-block label-custom">Domain</label>
                <!-- END: Label -->
                <!-- BEGIN: Input -->
                <input
                  id="domain"
                  name="domain"
                  v-model="form.domain"
                  :state="getValidationState(validationContext)"
                  :class="{
                    'is-invalid': validationContext.errors[0],
                    'is-valid': validationContext.dirty && validationContext.valid,
                  }"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="https://www.agentimage.com/"
                >
                <!-- END: Input -->

                <!-- BEGIN: Error Message -->
                <div class="invalid-feedback">
                  {{ validationContext.errors[0] }}
                </div>
                <!-- END: Error Message -->
                <small class="form-text text-muted">With HTTP Protocol</small>
              </validation-provider>
              <!-- END: Validation -->
            </div>
            <!-- END: Form Group -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <!-- BEGIN: Form Group -->
            <div class="form-group">
              <!-- BEGIN: Validation -->
              <validation-provider
                rules="validIPv4"
                v-slot="validationContext"
                name="Internet Protocol"
                vid="isp"
              >
                <!-- BEGIN: Label -->
                <label for="isp" class="d-block label-custom">Internet Protocol</label>
                <!-- END: Label -->
                <!-- BEGIN: Input -->
                <input
                  id="isp"
                  name="isp"
                  v-model="form.isp"
                  :state="getValidationState(validationContext)"
                  :class="{
                    'is-invalid': validationContext.errors[0],
                    'is-valid': validationContext.dirty && validationContext.valid,
                  }"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="0.0.0.0"
                  :disabled="form.wildcard === 1"
                >
                <!-- END: Input -->

                <!-- BEGIN: Error Message -->
                <div class="invalid-feedback">
                  {{ validationContext.errors[0] }}
                </div>
                <!-- END: Error Message -->
              </validation-provider>
              <!-- END: Validation -->
            </div>
            <!-- END: Form Group -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row">
          <!-- BEGIN: Column -->
          <div class="col">
            <!-- BEGIN: Form Group -->
            <div class="form-group custom-control custom-checkbox">
              <!-- BEGIN: Validation -->
              <validation-provider>
                <!-- BEGIN: Input -->
                <input
                  id="wildcard"
                  name="isp"
                  v-model="form.wildcard"
                  type="checkbox"
                  class="custom-control-input"
                  :true-value="1"
                  :false-value="0"
                >
                <!-- END: Input -->
                <!-- BEGIN: Label -->
                <label for="wildcard" class="custom-control-label">Internet Protocol</label>
                <!-- END: Label -->
              </validation-provider>
              <!-- end: Validation -->
            </div>
            <!-- END: Form Group -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->

        <!-- BEGIN: Row -->
        <div class="row justify-content-end">
          <!-- BEGIN: Column -->
          <div class="col-auto">
            <!-- BEGIN: Button -->
            <div class="form-group d-flex justify-content-end mb-0">
              <button type="submit" class="btn btn-lg btn-primary" :disabled="spinner || dirty === false || valid === false || (form.isp === '' && form.wildcard === 0)">
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-show="spinner"></span>
                Submit
              </button>
            </div>
            <!-- END: Button -->
          </div>
          <!-- END: Column -->
        </div>
        <!-- END: Row -->
      </form>
    </validation-observer>
    <!-- END: Form -->
  </div>
</template>

<script>
  import global from '@/mixins/global'

  export default {
    mixins: [global],

    data() {
      return {
        form: {
          domain: '',
          isp: '',
          wildcard: 0,
        },
        spinner: false,
      }
    },

    methods: {

      async submit()
      {
        this.spinner = true

        try {
          const {data} = await this.$http.post(`${process.env.VUE_APP_API_URL}/wp/unblock`, this.form)

          for (const [key, value] of Object.entries(data)) {
            this.$notify({
              type: key,
              text: value,
            })
          }

          this.spinner = false
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'There is an issue sending data to the domain either the domain doesn\'t exist or Initial Setup is not activated.',
          })

          this.spinner = false
        }
      }
    },
  }
</script>
