// Install VeeValidate rules and localization
import * as rules from 'vee-validate/dist/rules'
import {extend} from 'vee-validate'
import * as validate from './en'

Object.keys(rules).forEach((rule) => {
  // extend(rule, rules[rule])
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // eslint-disable-next-line no-undef
    message: validate.messages[rule] // assign message
  })
})

// for guidelines https://logaretm.github.io/vee-validate/guide/basics.html#messages
extend('validUrl', {
  validate: (value) => {
    return /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(value)
  },
  message: 'The {_field_} is not a valid domain',
})

extend('validIPv4', {
  validate: (value) => {
    return /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)
  },
  message: 'The {_field_} is not a valid domain',
})
