import NotFound from './../views/fallback/NotFound.vue'

const fallbackRouters = [
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
    meta: {
      layout: 'nosidebar',
    }
  },
  {
    path: '*',
    redirect: '/not-found'
  }
]

export default fallbackRouters
