<template>
  <div class="flex-center position-ref full-height">
    <div class="content">
      <div  class="m-b-md">
        <h2 class="title m-b-md">
          Not Found
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
