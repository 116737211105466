<template>
  <div class="default-wrapper">
    <!-- BEGIN: Sidebar -->
    <sidebar></sidebar>
    <!-- END: Sidebar -->
    <!-- BEGIN: Main Content -->
    <div class="main-content w-100" :class="`page-${$route.name}`">
      <div class="page-content">
        <div class="container-fluid">

          <div class="row">
            <div class="col-12">
              <div id="page-title" class="row" v-if="$route.meta.displayTitle !== false">
                <div class="col">
                  <h1>{{ $route.meta.title }}</h1>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <slot></slot>
            </div>
          </div>

        </div>
      </div>

      <div class="container-fluid copyright mt-5">
        Copyright {{ new Date().getFullYear()  }} <a href="https://www.agentimage.com" target="_blank">Agent Image</a>, a Division of The Design People, Inc. All Rights Reserved.
      </div>
    </div>
    <!-- END: Main Content -->
  </div>
</template>

<script>
  import Sidebar from './components/Sidebar'

  export default {
    components: {
      'sidebar': Sidebar
    },

    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/login')
      }
    },
  }
</script>
