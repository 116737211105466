<template>
  <div>
    <main :class="{'dark-theme': nightMode, 'disintegration': collapse}">
      <notifications/>
      <vue-progress-bar></vue-progress-bar>
      <components :is="layout">
        <router-view></router-view>
      </components>
    </main>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  const default_layout = 'default'

  export default {
    data(){
      return {
        name : null
      }
    },

    computed: {
      ...mapGetters({
        nightMode: 'global/getNightMode',
        collapse: 'global/getCollapse',
      }),

      layout() {
        let viewLayout = undefined;

        this.$route.matched.some(m => {
          if (m.meta.layout !== undefined) {
            viewLayout = m.meta.layout
          }
        })

        return (viewLayout || default_layout) + '-layout';
      }
    },
  }
</script>

<style lang="scss">
  @import "./scss/app";
</style>
